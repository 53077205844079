import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as ConfirmationsStore from '../store/Confirmations';
import SortableTable  from '../components/confirmations/ConfirmationsTable'

// At runtime, Redux will merge together...
type ConfirmationsProps =
  ConfirmationsStore.ConfirmationsState // ... state we've requested from the Redux store
    & typeof ConfirmationsStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters


class ConfirmationsFetchDataSortable extends React.PureComponent<ConfirmationsProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.ensureDataFetched();
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    this.ensureDataFetched();
  }

  public render() {
    return (
      <React.Fragment>
        <h1 id="tabelLabel">Confirmations</h1>
        <p>This component demonstrates fetching data from the server and working with URL parameters.</p>
         <SortableTable data={this.props.confirmations} />
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  private ensureDataFetched() {
    const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      this.props.requestConfirmations(startDateIndex);
  }


  private renderPagination() {
    const prevStartDateIndex = (this.props.startDateIndex || 0) - 5;
    const nextStartDateIndex = (this.props.startDateIndex || 0) + 5;

    return (
      <div className="d-flex justify-content-between">
        <Link className='btn btn-outline-secondary btn-sm' to={`/fetch-confirmations/${prevStartDateIndex}`}>Previous</Link>
        {this.props.isLoading && <span>Loading...</span>}
            <Link className='btn btn-outline-secondary btn-sm' to={`/fetch-confirmations/${nextStartDateIndex}`}>Next</Link>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.confirmations, // Selects which state properties are merged into the component's props
    ConfirmationsStore.actionCreators // Selects which action creators are merged into the component's props
)(ConfirmationsFetchDataSortable as any);
