import { MouseEventHandler, useCallback, useState } from "react";
import * as React from 'react';
import { Confirmation } from '../../store/Confirmations';
import styles from './ConfirmationsTable.module.css'; 


type ConfirmationsData = Confirmation[];

type SortKeys = keyof ConfirmationsData[0];

type SortOrder = "ascn" | "desc";

function sortData({
    tableData,
    sortKey,
    reverse,
}: {
    tableData: Confirmation[];
    sortKey: SortKeys;
    reverse: boolean;
}) {
    if (!sortKey) return tableData;

    const sortedData = tableData.sort((a, b) => {
        return a[sortKey] > b[sortKey] ? 1 : -1;
    });

    if (reverse) {
        return sortedData.reverse();
    }

    return sortedData;
}

function SortButton({
    sortOrder,
    columnKey,
    sortKey,
    onClick,
}: {
    sortOrder: SortOrder;
    columnKey: SortKeys;
    sortKey: SortKeys;
    onClick: MouseEventHandler<HTMLButtonElement>;
}) {
    return (
        <button
            onClick={onClick}
            className={`${sortKey === columnKey && sortOrder === "desc"
                    ? `${styles.sortbutton} ${styles.sortreverse}`
                : `${styles.sortbutton}`
                }`}
    >
      ▲
</button>
  );
}

function SortableTable({ data }: { data: ConfirmationsData }) {
    const [sortKey, setSortKey] = useState<SortKeys>("id");
    const [sortOrder, setSortOrder] = useState<SortOrder>("ascn");


    const headers: { key: SortKeys; label: string }[] = [
        { key: "id", label: "Id" },
        { key: "orderNumber", label: "Order Number" },
        { key: "confirmationNumber", label: "Confirmation Number" },
        { key: "tradeDate", label: "Trade Date" },
        { key: "security", label: "Security" },
        { key: "buySell", label: "Buy/Sell" },
        { key: "units", label: "Units" },
        { key: "netProceeds", label: "Net Proceeds" }
    ];

    const sortedData = useCallback(
        () => sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" }),
        [data, sortKey, sortOrder]
    );

    function changeSort(key: SortKeys) {
        setSortOrder(sortOrder === "ascn" ? "desc" : "ascn");

        setSortKey(key);
    }

    return (
        <table className={styles.confirmations} aria-labelledby="tabelLabel">
            <thead>
                <tr className={styles.confirmations}>
                    {headers.map((row) => {
                        return (
                            <td className={styles.confirmations}  key={row.key}>
                                {row.label}{" "}
                                <SortButton
                                    columnKey={row.key}
                                    onClick={() => changeSort(row.key)}
                                    {...{
                                        sortOrder,
                                        sortKey,
                                    }}
                                />
                            </td>
                        );
                    })}
                </tr>
            </thead>

            <tbody className={styles.confirmations}>
                {sortedData().map((confirmation) => {
                    return (
                        <tr className={styles.confirmations} key={confirmation.id.toString()}>
                            <td className={styles.confirmations}>{confirmation.id}</td>
                            <td className={styles.confirmations}>{confirmation.orderNumber}</td>
                            <td className={styles.confirmations}>{confirmation.confirmationNumber}</td>
                            <td className={styles.confirmations}>{confirmation.tradeDate}</td>
                            <td className={styles.confirmations}>{confirmation.security}</td>
                            <td className={styles.confirmations}>{confirmation.buySell}</td>
                            <td className={styles.confirmations}>{confirmation.units}</td>
                            <td className={styles.confirmations}>{confirmation.netProceeds}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default SortableTable;